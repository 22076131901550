<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row>
      <v-col cols="12">
        <!-- dialog for future add property -->
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="6" lg="4" class="mt-2">
        <v-text-field
          filled
          rounded
          dense
          single-line
          clearable
          v-model="search"
          label="Filter"
          hide-details
          ><vue-fontawesome
            :icon="['far', 'search']"
            color="grey"
            slot="prepend-inner"
            class="mt-1 mr-1"
        /></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="properties"
          :sort-by="['streetName', 'streetNumber']"
          class="elevation-1"
        >
          <template v-slot:header.isCurrent="{}">
            <div v-if="isAdmin">Current</div>
          </template>
          <template v-slot:item.fullAddress="{ item }">
            <router-link :to="{ path: '/property/' + item.id }">
              <b style="color: #333333">{{ item.fullAddress }}</b>
              <vue-fontawesome
                :icon="['far', 'chevron-right']"
                color="#333333"
                class="ml-2"
              />
            </router-link>
            <!-- <b>{{ item.fullName }}</b> -->
          </template>
          <!-- <template v-slot:item.people[0].fullName="{ item }">
            {{ item.people[0].fullName }}
            <span class="text--secondary ml-4">{{ item.people[0].email }}</span>
          </template> -->
          <!-- <template v-slot:item.people[1].fullName="{ item }">
            <b v-if="item.people[1].fullName.length">{{
              item.people[1].fullName
            }}</b>
          </template> -->
          <template v-slot:item.isCurrent="{ item }">
            <div v-if="isAdmin">
              <vue-fontawesome
                v-if="item.isCurrent"
                small
                :icon="['far', 'check']"
              />
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link :to="{ path: '/property/' + item.id }">
              <v-btn icon small>
                <vue-fontawesome small :icon="['far', 'chevron-right']" />
              </v-btn>
            </router-link>
            <!-- <v-btn icon small @click="editCitizen(item, item.id)">
              <vue-fontawesome
                small
                :icon="['fas', 'pencil-alt']"
                class="mr-1"
              />
            </v-btn> -->
            <!-- <v-btn icon small @click="deleteCitizen(item, item.id)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PropertyService from '@/services/PropertyService'
import { DateTime } from 'luxon'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'Properties',
  components: { Snackbar },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000,
      },
      search: '',
      dateFormatted: '',
      dateFormattedDisplay: '',
      dateMenu: false,
      loading: false,
      properties: [],
      headers: [
        {
          text: 'Street Address',
          align: 'start',
          filterable: true,
          value: 'fullAddress',
        },
        {
          text: 'Street Number',
          align: ' d-none',
          // align: 'start',
          filterable: true,
          value: 'streetNumber',
        },
        {
          text: 'Street Name',
          align: ' d-none',
          // align: 'start',
          filterable: true,
          value: 'streetName',
        },
        {
          text: 'Name',
          align: 'start',
          filterable: true,
          value: 'people[0].fullName',
        },
        // {
        //   text: 'Email',
        //   align: 'start',
        //   filterable: true,
        //   value: 'people[0].email',
        // },
        {
          text: 'Name',
          align: 'start',
          filterable: true,
          value: 'people[1].fullName',
        },
        {
          text: '',
          align: 'middle',
          filterable: true,
          value: 'isCurrent',
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false,
        },
      ],
      // activities: activities
    }
  },
  watch: {
    date(val) {
      if (val) {
        this.dateFormatted = this.formatDate(this.date)
      }
    },
    dateMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    citizenDialog(val) {
      // this.isEdit
      val || this.closeCitizenDialog()
    },
    citizenDialogDelete(val) {
      val || this.closeCitizenDelete()
    },
  },
  async created() {
    this.loading = true
    try {
      const propertiesResponse = await PropertyService.getAll()
      if (!propertiesResponse) {
        return
      }
      this.properties = propertiesResponse.data
      var i
      var d
      for (i = 0; i < this.properties.length; i++) {
        for (d = 0; d < this.properties[i].dues.length; d++) {
          // console.log(this.properties[i].dues[d].year)
          // console.log(Number(this.$store.state.currentYear))
          if (
            this.properties[i].dues[d].year == this.$store.state.currentYear
          ) {
            this.properties[i].isCurrent = true
          }
        }
      }
      this.loading = false
    } catch (error) {
      this.snackbar.model = true
      this.snackbar.message = error
      this.snackbar.color = 'red'
      this.snackbar.icon = ['fas', 'exclamation-triangle']
      console.log(error)
    }
  },
  mounted() {},
  computed: {
    dobFormatted() {
      const dt = DateTime.now()
      // const str = dt.toISOString()
      // console.log(str)
      return dt.toISOString()
      // return this.citizen.dateOfBirth.substring(0, 10)
    },
    isAdmin() {
      return this.$store.state.user.isAdmin || false
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.citizenIndex === -1 ? 'New Property' : 'Edit Property'
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    },
  },
  methods: {
    reset() {
      this.valid = false
      this.$refs.loginForm.reset()
    },
    resetValidation() {
      this.$refs.loginForm.resetValidation()
    },
    validateForm() {
      this.$refs.loginForm.validate()
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date).toUTC().toFormat('MM/dd/yyyy')
      return dt
    },
    age(dateOfBirth) {
      const today = new Date()
      // const dob = this.citizen.dateOfBirth
      const dob = DateTime.fromISO(dateOfBirth)
      // console.log(dob)
      let age = today.getFullYear() - dob.toFormat('yyyy')
      const m = today.getMonth() - dob.toFormat('mm')
      if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
        age = age - 1
      }
      return age
    },
    dateNow() {
      const dt = DateTime.now()
      return dt.toISOString()
    },
    save(date) {
      this.$refs.dateMenu.save(date)
      this.dateFormattedDisplay = this.formatDate(date)
    },
  },
}
</script>
<style lang="scss" scoped></style>
